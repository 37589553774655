import gql from 'graphql-tag'

export const GetSubscriptionOrderSummaries = gql`
  query ($currentPage: Int, $perPage: Int, $orderBy: String, $search: String) {
    subscriptionOrderSummaries (currentPage: $currentPage, perPage: $perPage, orderBy: $orderBy, search: $search) {
      items {
        id
        adminShop {
          name
          code
          logoImageSet {
            xsSqImage
          }
        }
        user {
          name
          email
        }
        code
        status
        nextDeliveryOn
        subscriptionAt
        latestSubscriptionOrder {
          interval
          status
          statusLabel
          orderId
          deliveryOn
          formatDeliveryOn
          quantity
          item {
            displayName
            size
            itemImageOrItemGroupListImageSet {
              xsSqImage
            }
          }
        }
        subscriptionOrders {
          id
          orderId
          statusLabel
          formatDeliveryOn
        }
      }
      pageInfo {
        currentPage
        totalCount
        perPage
        orderBy
      }
    }
  }`

export const GetSubscriptionOrderSummary = gql`
   query ($subscriptionOrderSummaryId: ID, $subscriptionOrderSummaryCode: String) {
    subscriptionOrderSummary (subscriptionOrderSummaryId: $subscriptionOrderSummaryId, subscriptionOrderSummaryCode: $subscriptionOrderSummaryCode) {
      id
      code
      formatSubscriptionAt
      status
      statusLabel
      payJpCardId
      creditStatus
      createdOrderCount
      minDeliveryCount
      deliveredCount
      latestSubscriptionOrder {
        id
        deliveryName
        deliveryNameKana
        deliveryTel
        deliveryZipCode
        deliveryPrefecture
        deliveryCity
        deliveryAddress1
        deliveryAddress2
        
        interval
        displayInterval
        deliveryOn
        formatDeliveryOn
        deliveryTime
        displayDeliveryTime
        deliveryMemo
        
        status
        statusLabel
        orderId
        
        paymentType
        displayPaymentType
        
        quantity
        itemGroupId
        adminShop {
          name
          code
        }
        item {
          displayName
          isNowOnSale
          soldOut
        }
        itemGroup {
          frontUrl
          publishedStatus
          isNowOnSale
        }
        deliveryOnOptions {
          id
          text
        }
      }
      payJpCard {
        id
        expMonth
        expYear
        last4
        name
      }
    }
  }`

export const GetSubscriptionOrderSummaryForShippingHistory = gql`
   query ($subscriptionOrderSummaryId: ID, $subscriptionOrderSummaryCode: String) {
    subscriptionOrderSummary (subscriptionOrderSummaryId: $subscriptionOrderSummaryId, subscriptionOrderSummaryCode: $subscriptionOrderSummaryCode) {
      id
      code
      subscriptionOrders {
        id
        formatDeliveryOn
        orderId
        order {
          id
          code
          currentOrderSummary {
            orderStatus
            orderStatusLabel
          }
        }
      }
    }
  }`

export const GetSubscriptionOrderSelectOptions = gql`
query {
  selectOptions {
    subscriptionOrderIntervalOptions {
      id
      text
    }
    orderDeliveryTimeOptions {
      id
      text
    }
  }
}`
export const GetSubscriptionOrderNextDeliveryOnSelerctOption = gql`
   query ($subscriptionOrderSummaryId: ID, $subscriptionOrderSummaryCode: String) {
    subscriptionOrderSummary (subscriptionOrderSummaryId: $subscriptionOrderSummaryId, subscriptionOrderSummaryCode: $subscriptionOrderSummaryCode) {
      id
      code
      subscriptionOrders {
        id
        formatDeliveryOn
        orderId
        order {
          id
          code
          currentOrderSummary {
            orderStatus
            orderStatusLabel
          }
        }
      }
    }
  }`

export const UpdateSubscriptionOrderSummaryCard = gql`
mutation($input: UpdateSubscriptionOrderSummaryInput!) {
  updateSubscriptionOrderSummary(input: $input) {
    subscriptionOrderSummary {
      id
      payJpCard {
        id
        expYear
        expMonth
        last4
        name
      }
    }
  }
}`

export const StopSubscriptionOrderSummary = gql`
mutation($input: StopSubscriptionOrderSummaryInput!) {
  stopSubscriptionOrderSummary(input: $input) {
    subscriptionOrderSummary {
      id
      status
    }
    errors {
      message
      model
      attribute
    }
  }
}`

export const UpdateSubscriptionOrderSummary = gql`
mutation($input: UpdateSubscriptionOrderSummaryInput!) {
  updateSubscriptionOrderSummary(input: $input) {
    subscriptionOrderSummary {
      id
      adminShop {
        name
        code
      }
      user {
        name
        email
      }
      code
      status
      nextDeliveryOn
      subscriptionAt
      latestSubscriptionOrder {
        interval
        status
      }
      subscriptionOrders {
        id
        itemGroup {
          listImageOrItemGroupImageSet {
            xsSqImage
          }
        }
        item {
          displayName
          itemImageOrItemGroupListImageSet {
            xsSqImage
          }
        }
        quantity
        status
        deliveryOn
        orderShippedAt
      }
      payJpCard {
        id
        expYear
        expMonth
        last4
        name
      }
    }
    errors {
      model
      attribute
      message
    }
  }
}`

