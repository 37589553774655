<template lang="pug">
  .container
    .row
      .col-xs-12
        h1.heading-3.text-center.my-md 定期購入管理
        .contents-wrapper(v-if="subscriptionOrderSummary")
          .contents
            h2 お届け先

            AlertBase.mb-md(type="info" v-if="isStopping" )
              p この定期購入は停止されています。
              p 再度定期購入を行う場合は、商品ページより改めてお申し込みください。このページでは再開できませんのでご注意ください。

              a.link(v-if="isNowOnSale" :href="latestSubscriptionOrder.itemGroup.frontUrl") 平飼いたまごの定期購入申し込みはこちら

            .form-wrapper
              .item-wrapper
                .item
                  .title 氏名
                  .body {{latestSubscriptionOrder.deliveryName}}
                .item
                  .title シメイ(カナ)
                  .body {{latestSubscriptionOrder.deliveryNameKana}}
                .item
                  .title 電話番号
                  .body {{latestSubscriptionOrder.deliveryTel}}
                .item
                  .title 郵便番号
                  .body {{latestSubscriptionOrder.deliveryZipCode}}
                .item
                  .title 都道府県
                  .body {{latestSubscriptionOrder.deliveryPrefecture}}
                .item
                  .title 市区町村
                  .body {{latestSubscriptionOrder.deliveryCity}}
                .item
                  .title 番地
                  .body {{latestSubscriptionOrder.deliveryAddress1}}
                .item(v-if="latestSubscriptionOrder.deliveryAddress2")
                  .title ビル建物名
                  .body {{latestSubscriptionOrder.deliveryAddress2}}
              p 配送先の変更は、ご注文いただいたショップへ直接お問い合わせください。マイページでは変更できませんのでご注意ください。
              a.link(v-if="isRunning" :href="contactPagePath" ) お問い合わせはこちら
          .contents
            h2 お届け設定
            .form-wrapper
              .item-wrapper
                .item
                  .title 配送頻度
                  .body {{latestSubscriptionOrder.displayInterval}}
                .item
                  .title 次回お届け予定日
                  .body {{latestSubscriptionOrder.formatDeliveryOn}}
                .item
                  .title 配送希望時間
                  .body {{latestSubscriptionOrder.displayDeliveryTime}}
                .item(v-if="latestSubscriptionOrder.deliveryMemo")
                  .title 備考
                  .body {{latestSubscriptionOrder.deliveryMemo}}
              template(v-if="isRunning && !isWaiting" )
                p 配送先の変更は、ご注文いただいたショップへ直接お問い合わせください。マイページでは変更できませんのでご注意ください。
                a.link(:href="contactPagePath" ) お問い合わせはこちら
              .text-center
                ButtonBase(v-if="isRunning" variant="primary" @click="openModalSubscriptionDelivery()" :disabled="!isWaiting") お届け日時を変更
                ButtonBase(v-if="isStopping" variant="primary" :disabled="true") この定期購入は停止済みです
          .contents
            h2 お支払い方法
            .form-wrapper
              .item-wrapper
                .item
                  .title お支払い方法
                  .body {{latestSubscriptionOrder.displayPaymentType}}
                template(v-if="latestSubscriptionOrder.paymentType === 'credit_card'" )
                  .item
                    .title 番号
                    .body **** **** **** {{subscriptionOrderSummary.payJpCard.last4}}
                  .item
                    .title 名前
                    .body {{subscriptionOrderSummary.payJpCard.name}}
                  .item
                    .title 有効期限
                    .body {{subscriptionOrderSummary.payJpCard.expMonth}}/{{subscriptionOrderSummary.payJpCard.expYear}}
                AlertBase(v-if="subscriptionOrderSummary.creditStatus === 'credit_ng'" type="warning")
                  p クレジットカードでエラーが発生しています。利用可能なクレジットカードを登録してください。
              .text-center(v-if="latestSubscriptionOrder.paymentType === 'credit_card' && isRunning" )
                ButtonBase(variant="primary" @click="openCreditCardModal()") クレジットカード情報を変更
          .contents
            h2 金額
            .form-wrapper
              .item-wrapper(v-if="recentlyOrder")
                .item
                  .title 商品単価{{recentlyOrder.currentOrderPayment.isAddTax ? "(税込)" : ""}}
                  .body {{recentlyOrder.currentOrderItems[0].priceInTaxWithCurrency}}
                .item
                  .title 商品小計{{recentlyOrder.currentOrderPayment.isAddTax ? "(税込)" : ""}}
                  .body {{recentlyOrder.currentOrderPayment.itemTotalPriceInTaxWithCurrency}}
                .item(v-if="recentlyOrder.currentOrderPayment.paymentType === 'cash_on_delivery'" )
                  .title 代引き手数料{{recentlyOrder.currentOrderPayment.isAddTax ? "(税込)" : ""}}
                  .body {{recentlyOrder.currentOrderPayment.codFeeInTaxWithCurrency}}
                .item
                  .title 送料
                  .body {{recentlyOrder.currentOrderPayment.shippingPriceInTaxWithCurrency}}
                .item(v-if="recentlyOrder.currentOrderPayment.usePoint !== '0'" )
                  .title 利用ポイント
                  .body {{recentlyOrder.currentOrderPayment.usePointWithCurrency}}
                .item
                  .title お支払い金額{{recentlyOrder.currentOrderPayment.isAddTax ? "(税込)" : ""}}
                  .body {{recentlyOrder.currentOrderPayment.orderTotalPriceWithCurrency}}
          .contents
            h2 お申込み情報
            .form-wrapper
              .item-wrapper
                .item
                  .title ショップ
                  .body {{latestSubscriptionOrder.adminShop.name}}
                .item
                  .title 商品名
                  .body {{latestSubscriptionOrder.item.displayName}}
                .item
                  .title 数量
                  .body {{latestSubscriptionOrder.quantity}}点
                .item
                  .title お申し込み番号
                  .body {{subscriptionOrderSummary.code}}
                .item
                  .title お申し込み日時
                  .body {{subscriptionOrderSummary.formatSubscriptionAt}}
                .item
                  .title お申し込み状況
                  .body {{subscriptionOrderSummary.statusLabel}}
          .contents
            .form-wrapper
              .item-wrapper
                p.bold 一度停止した定期購入はマイページから再開することはできません。再度申し込みを行う場合は、商品ページよりお申し込みください。

                p.warning(v-if="isRunning && latestSubscriptionOrder.orderId")
                  | {{latestSubscriptionOrder.formatDeliveryOn}}お届け分はすでに出荷準備が進んでいるため、停止ボタンからお手続き頂いても停止できません。その次のお届け分から停止となります。{{latestSubscriptionOrder.formatDeliveryOn}}お届け分から停止をご希望の場合、状況によってはご対応可能な場合がございますため、下記リンクよりショップへ直接お問い合わせください。
                p.warning(v-if="isRunning && !isOverDeliveryCount" )
                  | この商品は{{subscriptionOrderSummary.minDeliveryCount}}回以上お届けする定期購入商品です。{{subscriptionOrderSummary.minDeliveryCount}}回以上商品を受け取った後、定期購入を停止できます。
                a.link(v-if="isRunning && isShipping" :href="contactPagePath" ) ショップへ問い合わせ
                a.link(v-if="isStopping && isNowOnSale" :href="latestSubscriptionOrder.itemGroup.frontUrl") 平飼いたまごの定期購入申し込みはこちら

              .text-center
                ButtonBase(v-if="isRunning" variant="delete" @click="openOrderStopModal" :disabled="!isOverDeliveryCount") 定期購入を停止
                ButtonBase(v-else variant="delete" @click="openOrderStopModal" :disabled="true") この定期購入を停止済みです
          .contents
            h2 出荷履歴
            .form-wrapper
              .item-wrapper
                .item(v-for="subscriptionOrder of subscriptionOrderList", :index="subscriptionOrder.id" )
                  template(v-if="subscriptionOrder.order" )
                    .title {{subscriptionOrder.formatDeliveryOn}}お届け分
                    .body
                      p 受注番号：{{subscriptionOrder.order.code}}
                      p {{subscriptionOrder.order.currentOrderSummary.orderStatusLabel}}
                RouterLink(class="link" :to="{name: 'mypage-subscription-orders-shipping'}", :params="{code: subscriptionOrderSummary.code}") 全ての出荷履歴を見る

          ButtonBase(:to="{name: 'mypage-subscription-orders'}" variant="normal") 定期購入一覧へ戻る


          ModalSubscriptionDelivery(ref="ModalSubscriptionDelivery" :subscriptionOrder="latestSubscriptionOrder" @update="updateLatestSubscriptionOrder")
          ModalCreditCard(ref="ModalCreditCard"
            :subscriptionOrderSummaryId="subscriptionOrderSummary.id"
            :payJpCardId="subscriptionOrderSummary.payJpCardId"
            @update="updateCreditCard"
            )
    ModalBase(ref="ModalOrderStop" size="medium")
      template(slot="header") 停止
      template(slot="body")
        AlertBase(v-if="errors && errors.length > 0" type="error")
          p(v-for="error of errors") {{error.message}}
        p 定期購入を停止しますか?
        AlertBase(v-if="latestSubscriptionOrder.orderId" type="warning")
          p {{latestSubscriptionOrder.formatDeliveryOn}}お届け分は停止できません。その次のお届け分から停止します。

      template(slot="footer")
        ButtonBase(@click="closeOrderStopModal" variant="normal" size="inline") キャンセル
        ButtonBase(@click="stopSubscriptionOrder" variant="delete" size="inline" ) 停止

</template>

<script>
import { mapActions } from 'vuex'
import GlobalMixin from "../../mixin/GlobalMixin";
import {
  GetSubscriptionOrderSummary, StopSubscriptionOrderSummary,
} from "../../graphqls/SubscriptionOrderSummaries";

import ButtonBase from '../../components/v1/button/ButtonBase'
import ModalSubscriptionDelivery from '../../components/v1/modal/ModalSubscriptionDelivery'
import ModalCreditCard from '../../components/v1/modal/ModalCreditCard'
import {GetSubscriptionOrders} from "../../graphqls/SubscriptionOrder";
import AlertBase from "../../components/v1/alert/AlertBase";
import ModalBase from "../../components/v1/modal/ModalBase";

export default {
  name: "SubscriptionOrdersShow",
  mixins: [GlobalMixin],
  components: {
    ModalBase,
    AlertBase,
    ButtonBase,
    ModalSubscriptionDelivery,
    ModalCreditCard,
  },
  data() {
    return {
      subscriptionOrderSummary: {
        id: "",
        code: "",
        formatSubscriptionAt: "",
        status: "",
        statusLabel: "",
        payJpCardId: "",
        minDeliveryCount: "",
        deliveredCount: "",
        createdOrderCount: "",
        latestSubscriptionOrder: {
          id: "",
          deliveryName: "",
          deliveryNameKana: "",
          deliveryTel: "",
          deliveryZipCode: "",
          deliveryPrefecture: "",
          deliveryCity: "",
          deliveryAddress1: "",
          deliveryAddress2: "",
          displayInterval: "",
          formatDeliveryOn: "",
          deliveryOn: "",
          displayDeliveryTime: "",
          deliveryMemo: "",
          displayPaymentType: "",
          paymentType: "",
          quantity: "",
          itemGroupId: "",
          adminShop: {
            name: "",
            code: "",
          },
          item: {
            displayName: "",
          },
          orderId: "",
          deliveryOnOptions: [],
        }
      },
      subscriptionOrders: {
        items: []
      },
      pageInfo: {
        currentPage:  Number(this.$route.params.page || 1),
        totalCount: 0,
        perPage: 3
      },
      errors: []
    }
  },
  computed: {
    latestSubscriptionOrder() {
      return this.subscriptionOrderSummary.latestSubscriptionOrder
    },
    subscriptionOrderList() {
      return this.subscriptionOrders.items;
    },
    contactPagePath() {
      const subscriptionOrder = this.subscriptionOrderList.find((subscriptionOrder) => !!subscriptionOrder.orderId);
      return subscriptionOrder ? `/mypage/purchasehistory/${subscriptionOrder.orderId}/` : "/"
    },
    recentlyOrder() {
      const subscriptionOrder = this.subscriptionOrderList.find((subscriptionOrder) => !!subscriptionOrder.orderId);
      return subscriptionOrder?.order
    },
    isStopping() {
      return this.subscriptionOrderSummary?.status === 'stopping'
    },
    isRunning() {
      return this.subscriptionOrderSummary?.status === 'running'
    },
    isShipping() {
      return this.latestSubscriptionOrder.status === 'shipping'
    },
    isWaiting() {
      return this.latestSubscriptionOrder.status === 'waiting'
    },
    isOverDeliveryCount() {
      return this.subscriptionOrderSummary.minDeliveryCount && this.subscriptionOrderSummary.minDeliveryCount <= this.subscriptionOrderSummary.deliveredCount
    },
    isNowOnSale() {
      return this.latestSubscriptionOrder.itemGroup.publishedStatus === 'published'
          && this.latestSubscriptionOrder.itemGroup.isNowOnSale
          && this.latestSubscriptionOrder.item.isNowOnSale
          && !this.latestSubscriptionOrder.item.soldout
    }

  },
  metaInfo() {
    return {
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: this.$_itemListElement
        }
      }]
    }
  },
  async mounted() {
    this.loadingStart();
    await this.getSubscriptionOrderSummary();
    await this.getSubscriptionOrders();
    this.setBreadcrumbs({ breadcrumbs: [
        {
          path: '/',
          name: 'TOP',
        },
        {
          path: '/mypage/',
          name: 'マイページ',
        },
        {
          path: '/mypage/subscriptionorders/',
          name: '定期購入管理',
        },
        {
          path: `/mypage/subscriptionorders/${this.subscriptionOrderSummary.code}`,
          name: this.subscriptionOrderSummary.code,
        },
      ]})
    this.loadingStop();
  },
  methods: {
    ...mapActions([
      'setFlashMessage',
    ]),
    async getSubscriptionOrderSummary() {
      const { data } = await this.$apollo
          .query({
            query: GetSubscriptionOrderSummary,
            variables: {
              subscriptionOrderSummaryCode: this.$route.params.code,
              subscriptionOrderSummaryId: null
            },
            client: 'apiClient'
          })
          .catch(error => {
            this.loadingStop()
            return;
          });
      this.subscriptionOrderSummary = data.subscriptionOrderSummary
    },
    async getSubscriptionOrders() {
      const { data } = await this.$apollo
          .query({
            query: GetSubscriptionOrders,
            variables: {
              ...this.pageInfo,
              search: JSON.stringify(
                  {
                    subscription_order_summary_id_eq: this.subscriptionOrderSummary.id
                  })
            },
            client: 'apiClient'
          })
          .catch(error => {
            this.loadingStop()
            return;
          });
      this.subscriptionOrders = data.subscriptionOrders
      this.pageInfo = data.subscriptionOrders.pageInfo
    },
    openModalSubscriptionDelivery() {
      if(this.isWaiting) this.$refs.ModalSubscriptionDelivery.open();
    },
    openCreditCardModal() {
      if (this.isRunning) this.$refs.ModalCreditCard.open();
    },
    openOrderStopModal() {
      this.$refs.ModalOrderStop.open();
    },
    closeOrderStopModal() {
      this.$refs.ModalOrderStop.close();
    },
    updateCreditCard(updateSubscriptionOrderSummary) {
      this.subscriptionOrderSummary = { ...this.subscriptionOrderSummary, ...updateSubscriptionOrderSummary}
      this.setToast({ toast: { type: 'success', messages: ['変更が完了しました'] }})
    },
    updateLatestSubscriptionOrder(updateSubscriptionOrder) {
      this.subscriptionOrderSummary.latestSubscriptionOrder = { ...this.subscriptionOrderSummary.latestSubscriptionOrder, ...updateSubscriptionOrder.subscriptionOrder}
      this.setToast({ toast: { type: 'success', messages: ['更新が完了しました'] }})
    },
    async stopSubscriptionOrder() {
      this.loadingStart();
      this.errors = []
      const { data } = await this.$apollo
          .mutate({
            mutation: StopSubscriptionOrderSummary,
            // Parameters
            variables: {
              input: { id: this.subscriptionOrderSummary.id }
            },
            client: 'apiClient'
          })
          .catch(error => {
            console.log(error.message)
          });
      const result = data.stopSubscriptionOrderSummary
      const errors = result.errors
      if (!errors) {
        this.setFlashMessage({
          variant: 'success',
          messages: ['定期購入を停止しました。またのお申し込みをお待ちしております。'],
        })
        this.$router.push({ name: 'mypage-subscription-orders' })

      } else {
        this.errors = errors
      }
      this.loadingStop();
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/stylesheets/v1/styles";
.container {
  margin: auto;
  line-height: $line-height-snug;
  > .row {
    > .col-xs-12 {
      > .contents-wrapper {
          max-width: 800px;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 72px;
          align-self: stretch;
        > .contents {
            width: 100%;
          > h2 {
            text-align: center;
            margin: 2rem auto;
          }
          > .alert-base {
            > a.link {
              color: $olive-400;
              text-decoration-line: underline;
            }
          }

          > .form-wrapper {
            font-size: 14px;
            background: $ivory-100;
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;
            @include mq-down(){
              padding: 24px 8px;
            }
            > .item-wrapper {
              display: flex;
              padding: 16px;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              align-self: stretch;
              background: $white;
              > .bold {
                font-weight: bold;
              }
              > .warning{
                color: $red-300;
              }
              > a.link {
                color: $olive-400;
                text-decoration-line: underline;
              }
              > .item {
                > .title {
                  margin-bottom: 4px;
                  font-weight: bold;
                }
              }
              > .alert-base {
                width: 100%;
              }
            }
            > .text-center {
              width: 100%;
              margin-top: 12px;
            }
            > p {
              margin-top: 12px;
            }
            > a.link {
              color: $olive-400;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
}
</style>