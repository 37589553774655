<template lang="pug">
  .contact-show
    template(v-if="!results")
      AlertBase(v-if="recaptchaErrorMessage || validateErrors.length > 0" type="error")
        p(v-if="recaptchaErrorMessage") {{ recaptchaErrorMessage }}
        p(v-for="message of $_validationErrorMessage") {{message}}
      h1.heading-1.text-center.my-md {{ adminShop.name }}へのお問い合わせ
      p.paragraph.text-center.mb-sm お客さまから多くいただくご質問とその回答をFAQでご用意しています。
      div.text-center
        ButtonBase(variant="normal" href="https://essnu.notion.site/75acf162c73c4f86baf89697d51985ea" target="_blank") FAQ（よくあるご質問）
      AlertBase.mt-lg(v-if="adminShop.hasDeliveryTimerMemo" type="info")
        p.word-pre {{ adminShop.delivery.timerMemo }}
      ContainerFrame.mt-xlg.mb-xlg
        .form-wrapper
          AlertBase(v-if="adminShop.isSystemAdmin || !order" type="warning")
            template(v-if="adminShop.isSystemAdmin")
              p.bold こちらは、「たべるとくらすと運営本部」へのお問い合わせフォームです。
              p.bold 商品に関するご質問や配送について、またご注文内容の変更（商品、配送方法など）は、たべるとくらすとではお答えすることができません。直接ショップへお問い合せいただけますようお願いいたします。
              p.note お支払いやサイトのご利用方法に関するご質問は、たべるとくらすとまでお問い合わせ下さい。
              p.note 一括注文（請求書払い）をご希望の場合は、「お問い合わせの種類」にチェックを入れ、ご希望の商品ページURLと共にご連絡ください。
              p(v-if="!order")
                | すでにご注文した内容に関するお問い合わせは、
                a(href="/mypage/purchasehistory/") マイページの注文履歴
                | よりお手続きください。
            template(v-else)
              p.note こちらはお客様専用お問い合わせフォームとなります。営業メールは受け付けておりません。運営の妨害要因となる場合がございますので、お控えいただけますようお願いいたします。
              p.note 配送先が複数の一括注文（請求書払い）をご希望の場合は、「お問い合わせの種類：一括注文に関するお問合せ」にチェックを入れご希望の商品ページURLと共にご連絡ください。
              p(v-if="!order")
                | ご注文に関するお問い合わせは、
                a(href="/mypage/purchasehistory/") マイページの注文履歴
                | よりお手続きください。
            p(v-if="!canContact")
              | お問い合わせには、
              a(href="/login/") ログイン
              | が必要です。
          div.text-center.mt-xlg(v-if="!canContact")
            ButtonBase(href="/login/") ログインしてお問い合わせ
            p.mt-xs
              a(href="/regist/") 新規会員登録はこちら
          FormItem(v-if="!order")
            template(slot="title")
              FormLabel(type="required") お問い合わせの種類
            template(slot="input")
              FormRadioGroup
                FormRadio(v-model="form.contactType" id="customer_contact" label="一般のお客様のお問い合わせ" :disabled="!canContact")
                FormRadio(v-model="form.contactType" id="bulk_order_contact" label="一括注文（請求書払い）に関するお問い合わせ" :disabled="!canContact")
                FormRadio(v-model="form.contactType" id="wholesale_contact" label="卸販売に関するお問い合わせ" :disabled="!canContact")
          FormItem(v-if="order && order.id && form.contactType === 'order_contact'")
            template(slot="title")
              FormLabel ご注文内容
            template(slot="input")
              p.mb-xs
                | ご注文番号 : {{" "}}
                a(href="/mypage/purchasehistory/") {{order.code}}
              p.mb-xs ご注文日時 : {{order.orderedAt | moment('YYYY/MM/DD HH:mm')}}
          FormItem
            template(slot="title")
              FormLabel(type="required") お名前
            template(slot="input")
              FormTextfield(
                v-model="form.name"
                placeholder="田部倉 太郎"
                :error="'name' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.name"
                :disabled="!canContact"
              )
          FormItem
            template(slot="title")
              FormLabel(type="required") メールアドレス
            template(slot="input")
              FormTextfield(
                v-model="form.email"
                type="email"
                :placeholder="contactEmailAddress"
                :error="'email' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.email"
                :disabled="!canContact"
              )
          FormItem
            template(slot="title")
              FormLabel お電話番号
            template(slot="input")
              FormTextfield(
                v-model="form.tel"
                type="tel"
                placeholder="09012345678"
                :error="'tel' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.tel"
                :disabled="!canContact"
              )

          FormItem
            template(slot="title")
              FormLabel(type="required") お問い合わせ内容
            template(slot="input")
              FormTextarea(
                v-model="form.message"
                placeholder="お問い合わせ内容"
                :error="'message' in $_validationErrorMessage"
                :error-message="$_validationErrorMessage.message"
                :disabled="!canContact"
              )
          FormUploadImage.my-md(v-if="currentUser" ref="uploader" @onChange="uploadImage" :limit="5")
          AlertBase(type="info")
            p.note
              | ご注文に関するお問い合わせは、
              a(href="/mypage/purchasehistory/") マイページの注文履歴
              | よりお手続きください。
            p.note 携帯電話のメールアドレスでお問い合わせのお客さまは、あらかじめ「{{ contactEmailAddress }}」のメールアドレスを受信許可にしていただきますようお願いいたします。ご連絡のメールが受信ができない場合があります。
          div.text-center.mt-xlg
            ButtonBase(v-if="canContact" @click="contact()") 送信
            template(v-else)
              ButtonBase(href="/login/") ログインしてお問い合わせ
              p.mt-xs
                a(href="/regist/") 新規会員登録はこちら
    template(v-if="results")
      h1.heading-1.text-center.my-md {{ adminShop.name }}へのお問い合わせ
      ContainerFrame.mb-xlg
        .form-wrapper
          HeadingArticle.mb-md お問い合わせありがとうございました。
          p.paragraph.mb-md
            | 送信いただいた内容をご確認させていただきます。
            br
            | 後ほどご連絡いたしますので今しばらくお待ちください。
          AlertBase.mb-xlg(type="info")
            p.note 自動返信メールをお送りしましたのでご確認ください。自動返信メールが届いてない場合、迷惑メールフォルダ等に振り分けられている可能性があります。
            p.note 携帯電話のメールアドレスでお問い合わせのお客さまは、あらかじめ「{{ contactEmailAddress }}」のメールアドレスを受信許可にしていただきますようお願いいたします。ご連絡のメールが受信ができない場合があります。
          div.text-center
            ButtonBase(variant="normal" href="/") トップページへ戻る
</template>

<script>
  import { mapActions } from 'vuex'
  import { config } from "../../config/config"
  import { CreateContact } from "../../graphqls/Contacts"
  import { GetAdminShopForContact } from "../../graphqls/AdminShops"
  import { GetOrder } from "../../graphqls/Orders"
  import GlobalMixin from "../../mixin/GlobalMixin"
  import HeadingArticle from '../../components/v1/typography/HeadingArticle'
  import AlertBase from '../../components/v1/alert/AlertBase'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ContainerFrame from '../../components/v1/container/ContainerFrame'
  import FormItem from '../../components/v1/form/FormItem'
  import FormLabel from '../../components/v1/form/FormLabel'
  import FormRadioGroup from '../../components/v1/form/FormRadioGroup'
  import FormRadio from '../../components/v1/form/FormRadio'
  import FormTextarea from '../../components/v1/form/FormTextarea'
  import FormTextfield from '../../components/v1/form/FormTextfield'
  import {GetCurrentUser} from "../../graphqls/Users";
  import FormUploadImage from "../../components/v1/form/FormUploadImage";
  import {CreateContactComment} from "../../graphqls/ContactComments";

  export default {
    name: 'ContactShow',
    mixins: [GlobalMixin],
    components: {
      FormUploadImage,
      HeadingArticle,
      AlertBase,
      ButtonBase,
      ContainerFrame,
      FormItem,
      FormLabel,
      FormRadioGroup,
      FormRadio,
      FormTextarea,
      FormTextfield
    },
    metaInfo() {
      return {
        script: [{
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: this.$_itemListElement
          }
        }]
      }
    },
    data() {
      return {
        adminShop: {
          name: '',
        },
        form: {
          shopCode: '',
          contactType: 'customer_contact',
          name: '',
          email: '',
          tel: '',
          message: '',
          recaptchaToken: '',
          orderId: ''
        },
        results: null,
        order: null,
        images: []
      }
    },
    computed: {
      contactEmailAddress: function () {
        return this.adminShop.isSystemAdmin ? config.contact_email_address : this.adminShop.email
      },
      shopCode: function () {
        return this.$route.params.shop_code || 'tabekura'
      },
      currentUser() {
        return this.$store.getters.currentUser.user
      },
      canContact() {
        return this.currentUser || this.adminShop.isSystemAdmin
      }
    },
    methods: {
      uploadImage(list) {
        this.images = list.map(img => img.file)
      },
      async contact() {
        this.loadingStart()
        this.$_clearValidationErrors()

        this.form.shopCode = this.adminShop.code
        const token = await this.$_createRecaptchaToken('contacts')
        this.form.recaptchaToken = token
        for (let i = 0; i < 5; i++) {
          this.form[`image${i + 1}`] = this.images[i] || ''
        }

        const { data } = await this.$apollo.mutate({
          mutation: CreateContact,
          variables: {
            input: this.form
          }
        })
        .catch(error => {
          return this.$_redirect_internal_server_error(error)
        })

        const errors = data.createContact.errors
        if (errors.length) {
          this.$_parseError(errors)
        } else {
          const contact = data.createContact.contact
          if (contact.contactComments && contact.contactComments.length > 0 && contact.userNotice) {
            location.href = `/mypage/contacts/${contact.userNotice.id}/`
            return
          }
          this.results = data.createContact.contact
          this.$scrollTo("body")
        }
        this.loadingStop()
      },
      async getAdminShop () {
        const { data } = await this.$apollo.query({
          query: GetAdminShopForContact,
          variables: {
            adminShopCode: this.shopCode
          }
        })
        .catch(error => {
          this.$_redirect_internal_server_error(error)
        })
        this.adminShop = data.adminShop
        // パンくずリストを設定
        if (this.$route.name === 'contacts-show') {
          this.setBreadcrumbs({ breadcrumbs: [
            {
              path: '/',
              name: 'TOP',
            },
            {
              path: '/contacts/',
              name: `${this.adminShop.name}へのお問い合わせ`,
            },
          ]})
        } else if (this.$route.name === 'shop-contact-show') {
          this.setBreadcrumbs({ breadcrumbs: [
            {
              path: '/',
              name: 'TOP',
            },
            {
              path: `/shop/${this.adminShop.code}/`,
              name: this.adminShop.name,
            },
            {
              path: `/shop/${this.adminShop.code}/contact/`,
              name: `${this.adminShop.name}へのお問い合わせ`,
            },
          ]})
        }
      },
      async fetchCurrentUser () {
        this.loadingStart()
        const { data } = await this.$apollo
            .query({
              query: GetCurrentUser,
              client: 'apiClient'
            })
            .catch(error => {
              return this.$_redirect_internal_server_error(error)
            });
        this.$store.dispatch('setCurrentUser', { currentUser: data.currentUser })
        if (this.currentUser) {
          this.form.name = this.currentUser.name
          this.form.email = this.currentUser.email
          this.form.tel = this.currentUser.tel
        }
        this.loadingStop()
      },
      ...mapActions([
        'fetchCurrentShop',
      ])
    },
    mounted: function () {
      // CurrentUser取得
      this.fetchCurrentUser()
      // お問い合わせに使用するショップ情報取得
      this.getAdminShop()
      // サイド表示
      this.showSide()
      // サイドメニューのショップ情報表示
      this.fetchCurrentShop({
        shopCode: this.shopCode
      })
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";
</style>
