<template lang="pug">
  .items-show(v-if="itemGroup.id")
    // スライダー
    SectionItemSlider(:itemGroup="itemGroup")

    Section.section-review-link(v-if="hasReviewPageUrls" )
      a(:href="reviewPageUrls[0].url")
        include ../../../../assets/images/icon/pencil.svg
        | 生産者さんに商品の感想をお伝えしませんか？レビューの投稿でポイントプレゼント
    // SKUエリア
    SectionItemSKU#sku_top(
      :itemGroup="itemGroup"
      :selectedPrefectureId="selectedPrefectureId" @changePrefecture="changePrefecture"
      :quantity="quantity" @changeQuantity="changeQuantity"
      :selectedSkuItem="selectedSkuItem" @changeSelectedSkuItem="changeSelectedSkuItem"
      :subscriptionItemInterval="subscriptionItemInterval" @changeSubscriptionItemInterval="changeSubscriptionItemInterval"
      :giftForm="giftForm" @changeGift="changeGift"
      position="上"
    )

    // 商品説明
    SectionItemDescription.my-md(:itemGroup="itemGroup")

    // SKUエリア
    SectionItemSKU#sku_bottom(
      :itemGroup="itemGroup"
      :selectedPrefectureId="selectedPrefectureId" @changePrefecture="changePrefecture"
      :quantity="quantity" @changeQuantity="changeQuantity"
      :selectedSkuItem="selectedSkuItem" @changeSelectedSkuItem="changeSelectedSkuItem"
      :subscriptionItemInterval="subscriptionItemInterval" @changeSubscriptionItemInterval="changeSubscriptionItemInterval"
      :giftForm="giftForm" @changeGift="changeGift"
      position="下"
    )

    .shopping-guide
      .modal-link(@click="openPaymentModal")
        include ../../../../assets/images/icon/clone-regular.svg
        span.text お支払い方法
      .modal-link(@click="openReturnPolicyModal")
        include ../../../../assets/images/icon/clone-regular.svg
        span.text キャンセル・返品・交換
    ModalPayment(ref="paymentModal" :adminShop="adminShop")
    ModalReturnPolicy(ref="returnPolicyModal" :adminShop="adminShop")

    .button-block
      ButtonBase(:href="`/shop/${adminShopCode}/items/`") {{currentAdminShop.name}}商品一覧

    // レビュー・口コミ
    SectionItemReview.my-lg(v-if="itemGroupId" :itemGroup="itemGroup")
    // QA
    SectionItemQA.my-lg(v-if="isQaSupport && itemGroupId" :itemGroup="itemGroup")

    // 関連記事
    SectionItemColumn(v-if="itemGroupId" :itemGroup="itemGroup")
    // レシピ
    SectionItemRecipe(v-if="itemGroupId" :itemGroup="itemGroup")
    // 商品に関するお知らせ
    SectionItemPost.mb-lg(v-if="itemGroupId" :itemGroup="itemGroup" :adminShop="adminShop")

    // ショップランキング
    SectionItemRanking(v-if="itemGroupId && adminShop.code" :adminShop="adminShop")
    // 同じカテゴリの人気商品
    SectionItemSameCategory(v-if="itemGroupSameCategories.length > 0"
      v-for="category in itemGroupSameCategories" :itemCategory="category" :key="category.id")
    // こんな商品も見ています
    SectionItemSameTag(v-if="itemGroupId" :itemGroupId="itemGroupId" :itemGroupName="itemGroupName")
    // 最近チェックした商品
    SectionItemArchive(v-if="itemGroupId" :excludeItemGroupId="itemGroupId")

    // タグ
    SectionItemTag.mb-md(v-if="itemGroupId" :itemGroupCode="itemGroupCode")
    // おすすめカテゴリ
    SectionItemCategory(v-if="itemGroupId" eventCategory="カテゴリバナー")
</template>

<script>
  import { mapActions } from 'vuex'
  import { GetItemGroupForApi} from '../../graphqls/ItemGroups'
  import { GetAdminShop } from '../../graphqls/AdminShops'
  import { GetCurrentUser } from '../../graphqls/Users'
  import { GetFavoriteItems } from '../../graphqls/FavoriteItems'
  import GlobalMixin from '../../mixin/GlobalMixin'
  import SectionItemSlider from '../../components/v1/section/SectionItemSlider'
  import SectionItemSKU from '../../components/v1/section/SectionItemSKUForApi'
  import SectionItemDescription from '../../components/v1/section/SectionItemDescription'
  import SectionItemQA from '../../components/v1/section/SectionItemQA'
  import SectionItemReview from '../../components/v1/section/SectionItemReview'
  import SectionItemColumn from '../../components/v1/section/SectionItemColumn'
  import SectionItemRecipe from '../../components/v1/section/SectionItemRecipe'
  import SectionItemPost from '../../components/v1/section/SectionItemPost'
  import SectionItemRanking from '../../components/v1/section/SectionItemRanking'
  import SectionItemSameCategory from '../../components/v1/section/SectionItemSameCategory'
  import SectionItemSameTag from '../../components/v1/section/SectionItemSameTag'
  import SectionItemArchive from '../../components/v1/section/SectionItemArchive'
  import SectionItemTag from '../../components/v1/section/SectionItemTag'
  import SectionItemCategory from '../../components/v1/section/SectionItemCategory'
  import ButtonBase from '../../components/v1/button/ButtonBase'
  import ModalPayment from '../../components/v1/modal/ModalPayment'
  import ModalReturnPolicy from '../../components/v1/modal/ModalReturnPolicy'
  import {UpdateItemGroupDailyPageView, UpdateItemGroupPageView} from "../../graphqls/DailyPageView";
  import {UpdateItemGroupHourlyPageView} from "../../graphqls/HourlyPageView";
  import {GetReviewPageUrls} from "../../graphqls/Reviews";

  export default {
    name: "items-show",
    mixins: [GlobalMixin],
    components: {
      SectionItemSlider,
      SectionItemSKU,
      SectionItemDescription,
      SectionItemQA,
      SectionItemReview,
      SectionItemColumn,
      SectionItemRecipe,
      SectionItemPost,
      SectionItemRanking,
      SectionItemSameCategory,
      SectionItemSameTag,
      SectionItemArchive,
      SectionItemTag,
      SectionItemCategory,
      ButtonBase,
      ModalPayment,
      ModalReturnPolicy,
    },
    metaInfo() {
      return {
        script: [
          {
            type: 'application/ld+json',
            json: {
              '@context': 'http://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: this.$_itemListElement
            }
          },
          {
            type: 'application/ld+json',
            json: this.jsonldProducts
          }
        ]
      }
    },
    data() {
      return {
        adminShop: {
          name: '',
          code: ''
        },
        itemGroup: {
          adminShop: {
            name: '',
            logoImageSet: {
              xsSqImage: "https://cdn.taberutokurasuto.com/image/common/noimage_100x100.gif"
            },
            prefectureName: '',
            minDeliveryTerm: '',
            maxDeliveryTerm: '',
            hasDeliveryTimerMemo: false,
            delivery: {
              fixedMemo: '',
              timerMemo: '',
            },
            freeShippingPrice: '',
            isDeliveryFreeShippingOn: false
          },
          name: '',
          itemGroupImageSets: [],
          storageType: '',
          showGiftArea: false,
          noshiPossible: false,
          wrappingPossible: false,
          messageCardPossible: false,
          isAlcohol: false,
          publishedNotice: false,
          firstSelectItem: null,
          items: []
        },
        selectedPrefectureId: '13', //東京
        quantity: 1,
        subscriptionItemInterval: "",
        selectedSkuItem: {
          id: '',
          size: '',
          skuName: '',
          properPriceInTax: '0',
          salePriceInTax: '0',
          sellingPriceInTax: '0',
          listImageOrItemGroupListImageUrl: '',
          innerCapacity: '',
          isSale: false,
          displaySaleName: '',
          grantPoint: 0,
          pointRate: 0,
          maxAddCartNum: 0,
          soldOut: false,
          isNowOnSale: false,
          isSubscriptionItem: false,
          subscriptionMinDeliveryCount: '',
          itemDeliveryMethod: {
            isPostDelivery: false,
            isCompactDelivery: false
          },
          capacityRatio: 100,
          orderMessage: '',
          subscriptionItemIntervals: [],
          displayItemDeliveryDayOfWeeks: '',
          displayShortestDeliveryOn: '',
          displayNextLimitOrderOn: '',
          icons: [],
          shippingRegionPriceList: [],
          displayItemFreeShippingRegions: ''
        },
        giftForm: {
          noshiId: '',
          noshiNameMiddle: '',
          noshiNameRight: '',
          noshiNameLeft: '',
          noshiLastName: '',
          noshiMemo: '',
          noshiNamePrintType: 'nothing',
          wrappingId: '',
          messageCardId: '',
          messageCardMessage: '',
        },
        reviewPageUrls: []
      }
    },
    computed: {
      currentAdminShop() {
        return this.adminShop
      },
      reviews() {
        return this.itemGroup?.publishedReviews || []
      },
      reviewNum() {
        return this.reviews.length
      },
      reviewsRating() {
        if (this.reviewNum === 0) return 0;
        let sumReview = 0
        this.reviews.forEach(review => sumReview = sumReview + review.stars)
        return Math.round(sumReview / this.reviewNum)
      },

      isQaSupport() {
        return this.currentAdminShop && this.currentAdminShop.qaSupportStatus === 'qa_support_on'
      },
      itemGroupId() {
        return this.itemGroup.id
      },
      itemGroupName() {
        return this.itemGroup.name
      },
      itemGroupCode() {
        return this.$route.params.item_group_code
      },
      adminShopCode() {
        return this.$route.params.admin_shop_code
      },
      itemGroupSameCategories() {
        return this.itemGroup ? this.itemGroup.itemGroupSameCategories : []
      },
      itemCategoriesList() {
        return this.itemGroup ? this.itemGroup.itemCategoriesList : []
      },
      hasReviewPageUrls() {
        return this.reviewPageUrls.length > 0
      },
      jsonldProducts() {
        if (!this.itemGroup.id) return {}
        return this.itemGroup.openItems.map(item => {
          let product = {
            '@context': 'http://schema.org',
            '@type': 'Product',
            sku: item.id,
            inProductGroupWithID: item.itemGroupId,
            name: item.skuName,
            image: this.itemGroup?.itemGroupImageSets[0]?.lgImage,
            brand: {
              '@type': 'Brand',
              name: this.itemGroup.adminShop.name,
              logo: this.itemGroup.adminShop.logoImageSet.mdSqImage
            }
          }

          let offers = {
            '@type': 'Offer',
            itemCondition: "http://schema.org/NewCondition",
            price: item.sellingPriceInTax,
            priceCurrency: "JPY"
          }
          if (item.soldOut || !item.isNowOnSale) {
            offers.availability = "http://schema.org/OutOfStock"
          } else {
            offers.availability = "http://schema.org/InStock"
          }
          product.offers = offers

          if (this.reviews.length > 0) {
            const aggregateRating = {
              '@type': 'AggregateRating',
              ratingCount: this.reviews.length,
              ratingValue: this.reviewsRating,
            }
            product.aggregateRating = aggregateRating
            const review = this.reviews.map((item) => ({
              '@type': 'Review',
              author: {
                '@type': 'Person',
                name: item.displayAuthor
              },
              datePublished: item.postedAt,
              reviewBody: item.comment
            }))
            product.review = review
          }

          return product
        })
      }
    },
    mounted() {
      this.fetchItemGroup()
      this.fetchAdminShop()
      this.fetchCurrentUser()
      this.fetchFavoriteItems()
      this.fetchReviewPageUrls()
      // サイド表示
      this.showSide()
      // サイドメニューのショップ情報表示
      this.fetchCurrentShop({
        shopCode: this.adminShopCode
      })
      // クーポン表示
      this.showShopCoupon({ shopCode: this.adminShopCode })
    },
    methods: {
      async fetchItemGroup() {
        this.loadingStart()
        const { data } = await this.$apollo
        .query({
          // query: GetItemGroupExpand,
          query: GetItemGroupForApi,
          variables: {
            code: this.itemGroupCode,
            adminShopCode: this.adminShopCode
          },
          client: 'apiClient'
        })
        this.itemGroup = data.itemGroup
        this.$store.dispatch('setCurrentItemGroup', { currentItemGroup: data.itemGroup })
        let firstSelectItem = this.itemGroup.openItems.find((item) => !item.soldOut && item.isNowOnSale)
        if (!firstSelectItem) { firstSelectItem = this.itemGroup.openItems[0]}
        this.changeSelectedSkuItem(firstSelectItem)
        // パンくずリストを設定
        if (this.itemCategoriesList.length > 0) {
          // カテゴリが設定されている場合
          const categoryList = this.itemCategoriesList[0]
          let breadcrumbs = [
            {
              path: '/',
              name: 'TOP',
            },
          ]
          for (let category of categoryList) {
            breadcrumbs.push(
              {
                path: `/shops/items/category/${category.code}/`,
                name: category.name,
              }
            )
          }
          breadcrumbs.push({
            path: `/shop/${this.itemGroup.adminShop.code}/item/${this.itemGroup.code}/`,
            name: this.itemGroup.name,
          })
          this.setBreadcrumbs({ breadcrumbs: breadcrumbs})
        } else {
          // カテゴリが設定されていない場合
          this.setBreadcrumbs({ breadcrumbs: [
            {
              path: '/',
              name: 'TOP',
            },
            {
              path: `/shop/${this.itemGroup.adminShop.code}/`,
              name: this.itemGroup.adminShop.name,
            },
            {
              path: `/shop/${this.itemGroup.adminShop.code}/item/${this.itemGroup.code}/`,
              name: this.itemGroup.name,
            },
          ]})
        }
        this.$nextTick(() => {
          if (this.$route.hash) {
            this.$scrollTo(this.$route.hash, 500, {offset: -60})
          }
          this.loadingStop()
        })
        this.$gtag.event('view_item', {
          currency: 'JPY',
          items: [{
            item_id: this.itemGroup.id,
            item_name: `${this.itemGroup.adminShop.name} ${this.itemGroup.name}`,
            affiliation: this.itemGroup.adminShop.code,
            item_brand: this.itemGroup.adminShop.name,
          }]
        })
        //PV計測用メソッド呼び出し
        this.updateItemGroupDailyPageView(this.itemGroup.id)
        this.updateItemGroupHourlyPageView(this.itemGroup.id)
      },
      async fetchCurrentUser () {
        const { data } = await this.$apollo
        .query({
          query: GetCurrentUser,
          client: 'apiClient'
        })
        this.$store.dispatch('setCurrentUser', { currentUser: data.currentUser })
        if(data.currentUser.user && data.currentUser.user.prefectureId) {
          this.selectedPrefectureId =  data.currentUser.user.prefectureId
        }
      },
      async fetchAdminShop() {
        const { data } = await this.$apollo
        .query({
          query: GetAdminShop,
          variables: { adminShopCode: this.adminShopCode }
        })
        this.adminShop = data.adminShop
      },
      async fetchReviewPageUrls () {
        const { data } = await this.$apollo
            .query({
              query: GetReviewPageUrls,
              variables: {
                itemGroupCode: this.itemGroupCode,
              },
              client: 'apiClient'
            })
        this.reviewPageUrls = data.reviewPageUrls.items
      },
      async fetchFavoriteItems() {
        const { data } = await this.$apollo
        .query({
          query: GetFavoriteItems
        })
        this.$store.dispatch('setFavoriteItems', data.favoriteItems.items)
      },
      async updateItemGroupDailyPageView(itemGroupId) {
        await this.$apollo
            .mutate({
              mutation: UpdateItemGroupDailyPageView,
              // Parameters
              variables: {
                input: {
                  itemGroupId: itemGroupId
                }
              },
              client: 'apiClient'
            })
            .catch(error => {
              return this.$_redirect_internal_server_error(error)
            });
      },
      async updateItemGroupHourlyPageView(itemGroupId) {
        await this.$apollo
            .mutate({
              mutation: UpdateItemGroupHourlyPageView,
              // Parameters
              variables: {
                input: {
                  itemGroupId: itemGroupId
                }
              },
              client: 'apiClient'
            })
            .catch(error => {
              return this.$_redirect_internal_server_error(error)
            });
      },
      changePrefecture(prefectureId) {
        this.selectedPrefectureId = prefectureId
      },
      changeQuantity(quantity) {
        this.quantity = quantity
      },
      changeSelectedSkuItem(item) {
        this.selectedSkuItem = item
        if (this.selectedSkuItem && this.selectedSkuItem.subscriptionItemIntervals.length > 0) {
          this.subscriptionItemInterval = this.selectedSkuItem.subscriptionItemIntervals[0].intervalDays
        }
      },
      changeSubscriptionItemInterval(interval) {
        this.subscriptionItemInterval = interval
      },
      changeGift(form) {
        this.giftForm = form
      },
      openPaymentModal() {
        this.$refs.paymentModal.open()
      },
      openReturnPolicyModal() {
        this.$refs.returnPolicyModal.open()
      },
      ...mapActions([
        'setCurrentItemGroup',
      ])
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../assets/stylesheets/v1/styles";
  .section-review-link {
    display: flex;
    justify-content: center;
    @include margin-top(sm);
    > a {
      color: $black-400;
      text-align: center;
      > svg {
        width: 16px;
        height: auto;
      }
    }
  }

  .shopping-guide {
    @include margin-top(xs);
    @include margin-bottom(md);
    display: flex;
    justify-content: flex-end;

    > .modal-link {
      @include margin-left(sm);
      > svg {
        width: 14px;
        height: auto;
        > path {
          fill: $black-400;
        }
      }
      > .text {
        @include font-size(xs);
        margin-left: 3px;
        color: $black-400;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .button-block {
    text-align: center;
    @include margin-top(md);
    @include margin-bottom(md);
  }
</style>
