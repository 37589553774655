import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// Apollo
import {apolloApiClient} from '../config/apollo'
// Apollo
import { GetCurrentUser } from "../graphqls/Users";

import ContentLayout from '../components/layout/ContentLayout.vue'


// About
import AboutIndex from '../views/about/Index'

// Contact
import ContactShow from '../views/contacts/Show'

// Coupon
import CouponShow from '../views/coupons/Show'

// Open Contact
import OpenContactsShow from '../views/open_contacts/Show'

// Gift
import EventOkome from '../views/events/Okome'

// Gift
import GiftShow from '../views/gifts/Show'

// Home
import HomeIndex from '../views/home/Index'

// Items
import ItemsIndex from '../views/items/Index'
import ItemsShow from '../views/items/Show'
import CategoryItemsIndex from '../views/items/Category'
import CompactItemsIndex from '../views/items/Compact'
import ForBusinessItemsIndex from '../views/items/ForBusiness'
import FreeShippingItemsIndex from '../views/items/FreeShipping'
import GiftItemsIndex from '../views/items/Gift'
import NewItemsIndex from '../views/items/New'
import OneTimeItemsIndex from '../views/items/OneTime'
import PopularItemsIndex from '../views/items/Popular'
import ProductionAreaItemsIndex from '../views/items/ProductionArea'
import ProductionPrefectureItemsIndex from '../views/items/ProductionPrefecture'
import SaleItemsIndex from '../views/items/Sale'
import SearchItemsIndex from '../views/items/Search'
import ShopItemsIndex from '../views/items/Shop'
import SubscriptionItemsIndex from '../views/items/Subscription'
import TagItemsIndex from '../views/items/Tag'
import WithInYearItemsIndex from '../views/items/WithInYear'

// Laws
import LawsShow from '../views/laws/Show'

// Lps
import LpsCovidFreeShipping from '../views/lps/LpsCovidFreeShipping'

// Mypage
import MypageIndex from '../views/mypage/Index'
import MypageCredit from '../views/mypage/Credit'
import MypageInvoice from '../views/mypage/Invoice'
import MypageOrderPurchaseHistory from '../views/mypage/PurchaseHistory'
import MypageSubscriptionorders from '../views/mypage/SubscriptionOrders'
import MypageSubscriptionordersShow from '../views/mypage/SubscriptionOrdersShow'
import MypageSubscriptionordersShipping from '../views/mypage/SubscriptionOrdersShipping'
import MypageOrderDetail from '../views/mypage/OrderDetail'
import MypageContactsIndex from '../views/mypage/ContactsIndex'
import MypageContactsShow from '../views/mypage/ContactsShow'
import MypageReviewsShow from '../views/mypage/ReviewsShow'
import MypageCouponIndex from '../views/mypage/CouponIndex'

// Recipes
import RecipesIndex from '../views/recipes/Index'
import ShopRecipesIndex from '../views/recipes/ShopIndex'

// Shops
import ShopsIndex from '../views/shops/Index'
import ShopsShow from '../views/shops/Show'

// Users
import UsersLogin from '../views/users/Login'
import UsersRecover from '../views/users/Recover'
import UsersRecoverPassword from '../views/users/RecoverPassword'
import UsersRegist from '../views/users/Regist'
import UsersRegistConfirm from '../views/users/RegistConfirm'
import MailMagazineUnsubscribe from '../views/users/MailMagazineUnsubscribe'
import {GetPosts} from "../graphqls/Posts";


Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      component: ContentLayout,
      children: [
        {path: '/', name: 'home-index', component: HomeIndex, meta: { controller: 'home' }},
        {path: 'about/', name: 'about-index', component: AboutIndex, meta: { controller: 'about' }},
        {path: 'coupon/:code', name: 'coupons-show', component: CouponShow, meta: { controller: 'coupon' }},
        {path: 'contacts/', name: 'contacts-show', component: ContactShow, meta: { controller: 'contacts' }},
        {path: 'event/okome', name: 'event-okome', component: EventOkome, meta: { controller: 'events' }},
        {path: 'opencontacts/', name: 'open-contacts-show', component: OpenContactsShow, meta: { controller: 'open_contacts' }},
        {path: 'shop/:shop_code/contact/', name: 'shop-contact-show', component: ContactShow, meta: { controller: 'contacts' }},
        {path: 'shop/:shop_code/law/', name: 'laws-show', component: LawsShow, meta: { controller: 'law' }},
        {path: 'gifts/:code/', name: 'gifts-show', component: GiftShow, meta: { controller: 'gifts' }},
        {path: 'login/', name: 'login', component: UsersLogin, meta: { controller: 'user' }},
        {path: 'regist/', name: 'regist', component: UsersRegist, meta: { controller: 'user' }},
        {path: 'regist/:confirmation_token/', name: 'regist-confirm', component: UsersRegistConfirm, meta: { controller: 'user' }},
        {path: 'mailmagazine_unsubscribe/', name: 'mailmagazine-unsubscribe', component: MailMagazineUnsubscribe, meta: { controller: 'user' }},
        {path: 'lp/covid_free_shipping/', name: 'lps-covid-free-shipping', component: LpsCovidFreeShipping, meta: { controller: 'lps' }},
        {path: 'mypage/', name: 'mypage-index', component: MypageIndex, meta: { controller: 'mypage' }},
        {path: 'mypage/credit/', name: 'mypage-credit', component: MypageCredit, meta: { controller: 'mypage' }},
        {path: 'mypage/invoice/', name: 'mypage-invoice', component: MypageInvoice, meta: { controller: 'mypage' }},
        {path: 'mypage/purchasehistory/', name: 'mypage-purchase-history', component: MypageOrderPurchaseHistory, meta: { controller: 'mypage' }},
        {path: 'mypage/subscriptionorders/', name: 'mypage-subscription-orders', component: MypageSubscriptionorders, meta: { controller: 'mypage' }},
        {path: 'mypage/subscriptionorders/:code', name: 'mypage-subscription-orders-show', component: MypageSubscriptionordersShow, meta: { controller: 'mypage' }},
        {path: 'mypage/subscriptionorders/:code/shipping', name: 'mypage-subscription-orders-shipping', component: MypageSubscriptionordersShipping, meta: { controller: 'mypage' }},
        {path: 'mypage/purchasehistory/:id/', name: 'mypage-order-detail', component: MypageOrderDetail, meta: { controller: 'mypage' }},
        {path: 'mypage/contacts/', name: 'mypage-contacts-index', component: MypageContactsIndex, meta: { controller: 'mypage' }},
        {path: 'mypage/coupon/', name: 'mypage-coupon-index', component: MypageCouponIndex, meta: { controller: 'mypage' }},
        // {path: 'mypage/contacts/new/', name: 'mypage-contacts-new', component: MypageContactsShow, meta: { controller: 'mypage' }},
        {path: 'mypage/contacts/:id/', name: 'mypage-contacts-show', component: MypageContactsShow, meta: { controller: 'mypage' }},
        {path: 'mypage/review/:id/', name: 'mypage-reviews-show', component: MypageReviewsShow, meta: { controller: 'mypage', title: '商品レビュー - たべるとくらすと' }},
        {path: 'recipes/', name: 'recipes-index', component: RecipesIndex, meta: { controller: 'recipes' }},
        {path: 'shop/:adminShopCode/recipes/', name: 'shop-recipes-index', component: ShopRecipesIndex, meta: { controller: 'recipes' }},
        {path: 'shop/:adminShopCode/items/', name: 'shops-items-index', component: ShopItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'shops-items-page-index', component: ShopItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shop/:adminShopCode/', name: 'shops-show', component: ShopsShow, meta: { controller: 'shop'}},
        {path: 'shops/items/', name: 'items-index', component: ItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'items-page-index', component: ItemsIndex, meta: { controller: 'items'}},]
        },
        {path: 'shops/items/category/:categoryCode//', name: 'category-items-index', component: CategoryItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'category-items-page-index', component: CategoryItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/compact/', name: 'compact-items-index', component: CompactItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'compact-items-page-index', component: CompactItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/forbusiness/', name: 'forbusiness-items-index', component: ForBusinessItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'forbusiness-items-page-index', component: ForBusinessItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/freeshipping/', name: 'free-shipping-items-index', component: FreeShippingItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'free-shipping-items-page-index', component: FreeShippingItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/freeshipping/region/:region/', name: 'free-shipping-region-items-index', component: FreeShippingItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'free-shipping-region-items-page-index', component: FreeShippingItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/gift/', name: 'gift-items-index', component: GiftItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'gift-items-page-index', component: GiftItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/new/', name: 'new-items-index', component: NewItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'new-items-page-index', component: NewItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/onetime/', name: 'onetime-items-index', component: OneTimeItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'onetime-items-page-index', component: OneTimeItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/popular/', name: 'popular-items-index', component: PopularItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'popular-items-page-index', component: PopularItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/productionarea/:region', name: 'production-area-items-index', component: ProductionAreaItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'production-area-items-page-index', component: ProductionAreaItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/prefecture/:prefecture/', name: 'production-prefecture-items-index', component: ProductionPrefectureItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'production-prefecture-page-items-index', component: ProductionPrefectureItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/sale/', name: 'sale-items-index', component: SaleItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'sale-items-page-index', component: SaleItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/search/', name: 'search-items-index', component: SearchItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'search-items-page-index', component: SearchItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/subscription/', name: 'subscription-items-index', component: SubscriptionItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'subscription-items-page-index', component: SubscriptionItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/tag/:tagCode/', name: 'tag-items-index', component: TagItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'tag-items-page-index', component: TagItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shops/items/within_year/', name: 'within-year-items-index', component: WithInYearItemsIndex, meta: { controller: 'items'},
          children: [{path: 'page/:page/', name: 'within-year-items-page-index', component: WithInYearItemsIndex, meta: { controller: 'items'}}]
        },
        {path: 'shop/:admin_shop_code/item/:item_group_code/', name: 'items-show', component: ItemsShow, meta: { controller: 'items'}},
        {path: 'shops/page/:page/', name: 'shops-page-index', component: ShopsIndex, meta: { controller: 'shop'}},
        {path: 'shops/', name: 'shops-index', component: ShopsIndex, meta: { controller: 'shop'}},
        {path: 'recover/password/:token', name: 'users-recover-password', component: UsersRecoverPassword, meta: { controller: 'users'}},
        {path: 'recover/', name: 'users-recover', component: UsersRecover, meta: { controller: 'users'}},
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // store.dispatch('loadingStart')
  fetchCurrentUser()
  if (to.name) {
    return next();
  } else {
    return next({path: '/'})
  }
})

router.afterEach(() => {
  // store.dispatch('loadingStop')
})

const fetchCurrentUser = async function () {
  const { data } = await apolloApiClient
    .query({
      query: GetCurrentUser,
      client: 'apiClient'
  })
  .catch(error => {
    error;
    return;
  });
  store.dispatch('setCurrentUser', { currentUser: data.currentUser })
}


// export default new VueRouter({ routes });
